<template>
  <div>
    <el-dialog
      visible
      title="Choose a client"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-select
        v-model="clientUuid"
        placeholder="Clients"
        size="mini"
        class="client-input"
        @change="getClient"
      >
        <el-option
          v-for="cl in clients.all()"
          :key="cl.uuid"
          :label="cl.name"
          :value="cl.uuid"
        />
      </el-select>
      <div
        class="invoice-date"
      >
        <div>Invoice date:</div>
        <el-date-picker
          v-model="invoiceDate"
          size="mini"
          type="date"
          placeholder="Invoice date"
        />
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click.prevent="$router.go(-1)"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="!client || !invoiceDate"
          @click="apply"
        >
          Continue
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ClientRepository } from '@timeragent/core';
import moment from 'moment';

export default {
  name: 'ModalClientPicker',

  data: () => ({
    clientRepository: new ClientRepository(),
    clientUuid: '',
    client: null,
    invoiceDate: moment(),
  }),

  computed: {
    clients() {
      return this.clientRepository.dataset;
    },
  },

  created() {
    this.clientRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
      },
    });
  },

  methods: {
    apply() {
      this.$emit('pick', this.client, this.invoiceDate);
    },
    getClient() {
      this.client = this.clients.firstWhere('uuid', this.clientUuid);
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
  a.el-button {
    text-decoration : none;
  }

  a.el-button:hover {
    text-decoration : none;
  }

  a.el-button:focus {
    text-decoration : none;
  }

  .client-input {
    width : 100%;
  }

  .invoice-date {
    margin-top : 20px;
    width      : auto;
  }
</style>
